export const REQUESTED = 'REQUESTED'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

export const BROWSER = 'browser'
export const MOBILE = 'mobile'
export const ENABLED = 'enabled'
export const DISABLED = 'disabled'

export const HANDLE_FIELD_CHANGE = 'handleFieldChange'

export const TEXT_INPUT = 'text_input'

export const ACCEPT = 'accept'
export const REJECT = 'reject'

export const STATUS_AUTHENTICATED = 1
export const STATUS_REGISTERED = 2
export const STATUS_UPDATED = 3

export const IMAGE_TYPE_DEFAULT = 1
export const IMAGE_TYPE_BARCODE = 2
export const IMAGE_TYPE_LABEL = 3

export const API_URL = 'https://sandboxapi.rosievision.ai/api'
//'http://localhost:8080/api'