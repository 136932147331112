import '../style/style.css'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLocation, updateLocationName, setCurrentLocation } from '../state/lib/actions'
import Images from '../assets/images/images'
import Element from '../style/screens/location_screen_styles'
import { FaPencilAlt } from 'react-icons/fa'
import Modal from 'react-modal';
import { GET_FULL_USER_LOCATIONS } from '../state/lib/action_types';

const LocationScreen = ({navigate}) => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.app.locations);
  const currentLocation = useSelector((state) => state.app.currentLocation);
  const userId = useSelector((state) => state.app.user.id);
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (currentLocation) {
      setLocationName(currentLocation.name || '');
      setLocationAddress(currentLocation.street_address1 || '');
    }
  }, [currentLocation]);

  const handleSaveChanges = async () => {
    if (!currentLocation?.id) return;
    
    try {
      dispatch(updateLocationName({
        locationId: currentLocation.id,
        name: locationName,
        address: locationAddress,
        userId: userId
      }));
      
      await new Promise(resolve => setTimeout(resolve, 500));
      
      setIsEditing(false);
      navigate('dashboard');
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteWarning(true);
  };

  const handleConfirmDelete = async () => {
    if (!currentLocation?.id) return;
    
    try {
      await dispatch(deleteLocation({
        locationId: currentLocation.id,
        userId: userId
      }));
      
      // Refresh locations list
      await dispatch({
        type: GET_FULL_USER_LOCATIONS,
        data: { userId: userId }
      });
      
      // Find next available location
      const remainingLocations = locations.filter(loc => loc.id !== currentLocation.id);
      if (remainingLocations.length > 0) {
        dispatch(setCurrentLocation(remainingLocations[0]));
      } else {
        dispatch(setCurrentLocation(null));
      }
      
      setShowDeleteWarning(false);
      navigate('dashboard');

      // Add this to refresh locations after navigation
      setTimeout(() => {
        dispatch({
          type: GET_FULL_USER_LOCATIONS,
          data: { userId: userId }
        });
      }, 100);
      
    } catch (error) {
      console.error('Error deleting location:', error);
    }
  };

  return (
    <Element>
      <Element.header>
        <Element.header.logo src={Images.rosieLogoColor}/>
        <Element.header.right>
          <Element.header.home src={Images.iconRoundColorWhiteBG}/>
          <Element.header.profile src={Images.avatar}/>
        </Element.header.right>
      </Element.header>
      <Element.form>
        <Element.locationName>
          {isEditing ? (
            <div style={{ width: '100%' }}>
              <Element.input
                type="text"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                placeholder="Location Name"
                autoFocus
              />
              <Element.input
                type="text"
                value={locationAddress}
                onChange={(e) => setLocationAddress(e.target.value)}
                placeholder="Address"
                style={{ marginTop: '10px', width: '100%' }}
              />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{currentLocation?.name}</div>
                <Element.locationAddress>
                  {currentLocation?.street_address1 || 'No address provided'}
                </Element.locationAddress>
              </div>
              <Element.picEdit onClick={() => setIsEditing(true)}>
                <FaPencilAlt color="white" size={14} />
              </Element.picEdit>
            </>
          )}
        </Element.locationName>
        <Element.btnDark onClick={handleSaveChanges}>
          Save Changes
        </Element.btnDark>
        <Element.btnLight onClick={handleDeleteClick}>
          Delete Location
        </Element.btnLight>
      </Element.form>

      <Modal
        isOpen={showDeleteWarning}
        onRequestClose={() => setShowDeleteWarning(false)}
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            maxWidth: '400px'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          }
        }}
      >
        <h2>Are you sure you want to delete this location?</h2>
        <p>All assets associated with this location will be permanently deleted. 
          To keep these assets, please move them to a different location before deleting.</p>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: '10px', 
          marginTop: '20px' 
        }}>
          <button 
            onClick={handleConfirmDelete}
            style={{ 
              backgroundColor: '#d32f2f', 
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Delete Anyway
          </button>
          <button 
            onClick={() => setShowDeleteWarning(false)}
            style={{ 
              backgroundColor: '#4CAF50',
              color: 'white',
              padding: '10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </Element>
  );
};

export default LocationScreen;
