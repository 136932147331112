import { ERROR, SUCCESS } from '../../constants/constants'
import { ADD_LOCATION, DELETE_LOCATION, UPDATE_LOCATION_NAME, SET_CURRENT_LOCATION, UPDATE_IDENTIFY, DELETE_ASSET } from './action_types'; // Ensure this import is present

export const action = (payload) => {
  const { type, data } = payload
  return {
    type,
    data,
  }
}
export const actionSuccess = (data, type) => ({
  type: `${type}-${SUCCESS}`,
  data,
})
export const actionError = (error, type) => ({
  type: `${type}-${ERROR}`,
  error,
})

// Action creator for adding a location
export const addLocation = (location) => ({
  type: ADD_LOCATION,
  payload: location,
});

export const deleteLocation = ({locationId, userId}) => ({
  type: DELETE_LOCATION,
  payload: {
    locationId,
    userId,
    is_archived: true,
    cascade: true
  }
})

export const updateLocationName = (payload) => ({
  type: UPDATE_LOCATION_NAME,
  payload: {
    locationId: payload.locationId,
    name: payload.name,
    address: payload.address,
    userId: payload.userId
  }
});

export const setCurrentLocation = (location) => ({
  type: SET_CURRENT_LOCATION,
  data: location
});

export const updateIdentify = (data) => ({
  type: UPDATE_IDENTIFY,
  data
})

export const deleteAsset = ({assetId, userId}) => ({
  type: DELETE_ASSET,
  payload: {
    assetId,
    userId,
    is_archived: true
  }
});

