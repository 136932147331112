import { spawn, all } from 'redux-saga/effects'
import {
  watchAddLocation,
  watchCreateAsset,
  watchGetAsset,
  watchGetFullUserLocations,
  watchInit,
  watchLogin,
  watchRegister,
  watchUpdateAsset,
  watchUploadImage,
  watchDeleteLocation,
  watchUpdateLocationName,
  watchDeleteAsset
} from '../app_sagas'

const rootSaga = function* () {
  yield all([
    spawn(watchCreateAsset),
    spawn(watchGetAsset),
    spawn(watchGetFullUserLocations),
    spawn(watchInit),
    spawn(watchLogin),
    spawn(watchRegister),
    spawn(watchUpdateAsset),
    spawn(watchUploadImage),
    spawn(watchAddLocation),
    spawn(watchDeleteLocation),
    spawn(watchUpdateLocationName),
    spawn(watchDeleteAsset)
  ])
}

export default rootSaga
